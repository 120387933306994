export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor: ['regiao'],
    },
    gates: [],
    params: {
      data_inicio: {
        value: '',
        visible: false,
      },
      data_fim: {
        value: '',
        visible: false,
        until: true,
      },
      gate: {
        value: '',
        visible: false,
        // get: () => { },
      },
      proprietario: {
        value: '',
        visible: false,
        // get: () => { },
      },
      fornecedor: {
        value: '',
        visible: false,
        //get: this.getFornecedores,
      },

      cliente: {
        value: '',
        visible: false,
        tipo: 'cliente',
        //get: this.getClientes,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
    },
    allOptions: ['data_inicio', 'data_fim', 'fornecedor', 'cliente', 'regiao'],
  }
}
