<template>
  <div class="d-flex justify-center align-center">
    <div>
      <span class="text-caption">Deseginado: </span>
      <span class="text-caption font-weight-bold">
        {{ resumo.quantidade_designada }}
      </span>
    </div>
    <v-divider class="mx-4 my-1" vertical></v-divider>
    <div>
      <span class="text-caption">Agendado: </span>
      <span class="text-caption font-weight-bold">
        {{ resumo.quantidade_agendado }}</span
      >
    </div>
    <v-divider class="mx-4 my-1" vertical></v-divider>
    <div>
      <span class="text-caption">Atendido: </span>
      <span class="text-caption font-weight-bold">
        {{ resumo.quantidade_atendido }}</span
      >
    </div>
    <v-divider class="mx-4 my-1" vertical></v-divider>
    <div>
      <span class="text-caption">Saldo: </span>
      <span class="text-caption font-weight-bold">
        {{ resumo.quantidade_saldo }}</span
      >
    </div>
    <v-divider class="mx-4 my-1" vertical></v-divider>
    <div>
      <span class="text-caption">Performance: </span>
      <span class="text-caption font-weight-bold">
        {{
          resumo.quantidade_performance.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        }}
      </span>
      <span class="text-caption"> %</span>
    </div>
  </div>
</template>

<script>
import { BasicPermissions } from '@/utils/permissions'

export default {
  props: {
    resumo: {
      type: Object,
      default: () => {
        return {
          quantidade_designada: 0,
          quantidade_agendado: 0,
          quantidade_atendido: 0,
          quantidade_saldo: 0,
          quantidade_performance: 0,
        }
      },
    },
    permission: {
      type: String,
      required: false,
      default: null,
    },
  },
  filters: {
    toLocaleString(value) {
      if (!value) return 0
      return parseFloat(value).toLocaleString()
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    canList() {
      if (!this.permission) return true
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
}
</script>
