import { mapGetters, mapState } from 'vuex'

import { PERFORMANCE_CLIENTE } from '@/utils/storages'
const LOCAL_STORAGE_ID = PERFORMANCE_CLIENTE

export const computed = {
  ...mapState('auth', ['user', 'empresaAtual']),
  ...mapState('fornecedores', ['fornecedores']),
  ...mapState('clientes', ['clientes']),
  ...mapState('transportadoras', ['transportadoras']),
  ...mapGetters('contratos', ['terminais']),
  ...mapState('patio', ['regioes', 'representantes']),
  ...mapState('motoristas', ['motoristas']),

  showData() {
    return this.params.data_inicio?.visible || this.params.data_fim?.visible
  },
  showFornecedor() {
    return this.params.fornecedor?.visible || this.params.regiao?.visible
  },
  showContratos() {
    return this.tipo == 'cliente' && this.params.cliente?.visible
  },
  areSettingsEmpty() {
    return !(this.showData || this.showFornecedor || this.showContratos)
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID.options)) || []
  },
  selectedFornecedor() {
    return this.params.fornecedor.value
  },
}
