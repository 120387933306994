import axios from 'axios'

class RelatorioApi {
  async getRelatorioGates(params) {
    try {
      const { data } = await axios.get(`api/v1/relatorios/gates/`, { params })
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async getRelatorioGatesOptions() {
    try {
      const { data } = await axios.options(`api/v1/relatorios/gates/`)
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getRelatorioPerformanceFornecedor(params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/patio/programacoesFornecedor/relatorio-performance/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getRelatorioPedidos(params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/patio/pedidos/produto-embalagem/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getRelatorioPerformanceRegiao(params) {
    try {
      const {
        data,
      } = await axios.get(
        `api/v1/patio/programacoesRegiao/relatorio-performance/`,
        { params }
      )
      return Promise.resolve(data)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new RelatorioApi()
