<template>
  <v-col>
    <validation-provider v-slot="{ errors }" :rules="rules" :name="name">
      <v-autocomplete
        v-model="value"
        label="Região"
        multiple
        :items="regioes"
        item-text="sigla"
        item-value="public_id"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        :error-messages="errors"
      ></v-autocomplete>
    </validation-provider>
  </v-col>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'RelatorioPerformanceRegiaoFiltroModalItemInputRegiao',
  props: {
    name: {
      type: String,
      required: true,
    },
    fornecedor: {
      type: [Object, String],
      required: true,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      search: '',
      regioes: [],
    }
  },
  methods: {
    ...mapActions('patio', ['getRegioes', 'getRegioesColaborator']),
    async atualizaRegioes(fornecedor) {
      if (this.empresaAtual.representantes.length > 0)
        return await this.getRegioesColaborator()
      return await this.getRegioes({
        public_id: fornecedor.fornecedor_id,
      })
    },
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
  },
  async created() {},
  watch: {
    async fornecedor(fornecedor) {
      this.regioes = await this.atualizaRegioes(fornecedor)
    },
  },
}
</script>
