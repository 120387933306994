const PERFORMANCE_CLIENTE = {
  options: 'PerfomanceClienteFilterOptions',
  details: 'PerfomanceClienteFilterDetails',
}
const SENHA_ATENDIMENTO = {
  options: 'SenhaAtendimentoFilterOptions',
  details: 'SenhaAtendimentoFilterDetails',
  storage: 'senha_atendimento',
  headers: 'headerSenhaAtendimento',
}
const LOGGING = {
  options: 'LoggingFilterOptions',
  details: 'LoggingFilterDetails',
  storage: 'logging',
  headers: 'headerLogging',
}

const setIndicadores = (LOCAL_STORAGE_ID, headers) => {
  let _headers
  const headersSaved = localStorage.getItem(LOCAL_STORAGE_ID.headers)
  const headersSavedParsed = JSON.parse(headersSaved)
  console.log(headersSavedParsed)
  console.log(_headers)
  if (
    headersSaved &&
    Array.isArray(headersSavedParsed) &&
    headersSavedParsed.length > 0
  ) {
    const headers_saved_map_habilited = headersSavedParsed
      .filter((item) => item.habilited)
      .map((item) => item.value)
    const headers_saved_map_not_habilited = headersSavedParsed
      .filter((item) => !item.habilited)
      .map((item) => item.value)
    _headers = headers.map((item) => {
      let habilited = false
      if (headers_saved_map_habilited.includes(item.value)) {
        habilited = true
      } else {
        if (headers_saved_map_not_habilited.includes(item.value)) {
          habilited = false
        } else {
          // Para os novos
          habilited = item.habilited
        }
      }
      return {
        ...item,
        habilited,
      }
    })
    localStorage.setItem(LOCAL_STORAGE_ID.headers, JSON.stringify(_headers))
  } else {
    _headers = headers
    localStorage.setItem(LOCAL_STORAGE_ID.headers, JSON.stringify(_headers))
  }
  return _headers
}

export { PERFORMANCE_CLIENTE, SENHA_ATENDIMENTO, LOGGING, setIndicadores }
