export const data = () => ({
  optionsSelected: [],
  dependents: {
    fornecedor: ['regiao'],
  },
  options_default: [
    {
      id: 10,
      name: 'Data',
      children: [
        {
          id: 'data_inicio',
          name: 'De',
        },
        {
          id: 'data_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Fornecedor',
      children: [
        {
          id: 'fornecedor',
          name: 'Fornecedor',
        },
        {
          id: 'regiao',
          name: 'Região',
        },
      ],
    },
  ],
  options_cliente: [
    {
      id: 30,
      name: 'Contratos',
      children: [
        {
          id: 'cliente',
          name: 'Cliente',
          tipo: 'cliente',
        },
      ],
    },
  ],
})
