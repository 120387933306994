import { mapActions } from 'vuex'

import { cnpj } from 'cpf-cnpj-validator'

import { PERFORMANCE_CLIENTE } from '@/utils/storages'
const LOCAL_STORAGE_ID = PERFORMANCE_CLIENTE

export const methods = {
  ...mapActions('fornecedores', ['getFornecedores']),
  ...mapActions('clientes', ['getClientes']),
  ...mapActions('transportadoras', ['getTransportadoras']),
  ...mapActions('contratos', ['getContratos']),
  ...mapActions('patio', [
    'getRegioes',
    'getRepresentantes',
    'getRegioesColaborator',
  ]),
  ...mapActions('motoristas', ['getMotoristas']),
  // ...mapMutations('gerenciamentoServico', ['UPDATE_PARAMS']),

  handleFixParams(fixedParams, [key, param]) {
    if (param.value) {
      const paramsWithCNPJ = []
      const paramsWithPublicId = ['fornecedor']
      const paramsMultiples = ['regiao']

      if (param.until == true) {
        if (param.value.trim().split(' ').length == 1) {
          param.value += ' 23:59:59'
        } else if (param.value.trim().split(' ')[1].split(':').length == 2) {
          param.value += ':59'
        }
      }

      if (paramsWithCNPJ.includes(key))
        fixedParams[key] = cnpj.strip(param.value)
      else if (paramsWithPublicId.includes(key))
        fixedParams[key] = this.params[key].value[key + '_id']
      else if (paramsMultiples.includes(key))
        fixedParams[key] = this.params[key].value.join(',')
      else if (param.tipo != undefined && param.tipo != this.tipo)
        console.log(':(')
      else fixedParams[key] = param.value
    }

    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(
      this.handleFixParams,
      new Object()
    )
  },

  addParamsFilter() {
    localStorage.setItem(LOCAL_STORAGE_ID.details, JSON.stringify(this.params))
  },

  getParamsFilter() {
    if (localStorage.getItem(LOCAL_STORAGE_ID.details)) {
      const params_local = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_ID.details)
      )
      Object.entries(params_local).forEach((item) => {
        if (item[0] in this.params)
          this.params[item[0]] = { ...this.params[item[0]], ...item[1] }
      })
    }
  },
  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = { ...this.fixParams() }
      // this.UPDATE_PARAMS(fixedParams)
      this.addParamsFilter()
      this.$emit('search', fixedParams)
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    if (localStorage.getItem(LOCAL_STORAGE_ID.details)) {
      const params_local = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_ID.details)
      )
      Object.entries(params_local).forEach((item) => {
        if (item[0] in this.params)
          this.params[item[0]] = { ...this.params[item[0]], ...item[1] }
      })
    }
    this.allOptions.forEach((someOption) => {
      if (this.params[someOption]) {
        this.params[someOption].visible = false
      } else {
        console.log(someOption + ' não encontrada!')
      }
    })
    this.selectedOptions.forEach((option) => {
      if (this.params[option]) {
        this.params[option].visible = true
      } else {
        console.log(option + ' não encontrada!')
      }
      //this.params[option].visible = true
    })
    this.allOptions.forEach((someOption) => {
      if (this.params[someOption]?.visible == false) {
        this.params[someOption].value = ''
      }
    })
    this.addParamsFilter()
    this.showDaddy()
  },

  showDaddy() {
    for (let [daddy, dependents] of Object.entries(this.dependents)) {
      const isSomeDependentVisible = dependents.some(
        (dependent) => this.params[dependent]?.visible
      )
      if (isSomeDependentVisible) {
        this.params[daddy].visible = true
      }
    }
  },
}
