<template>
  <v-col>
    <validation-provider v-slot="{ errors }" :rules="rules" :name="name">
      <v-autocomplete
        :items="fornecedoresSelecionados"
        :error-messages="errors"
        :value="value"
        :label="label"
        :prepend-icon="icon"
        :search-input.sync="search"
        item-text="trade_name"
        return-object
        @input="$emit('input', $event)"
        no-filter
      >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.trade_name"></v-list-item-title>
            <v-list-item-subtitle
              v-text="`CNPJ ${item.cnpj} - ${item.city} - ${item.district}`"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </validation-provider>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'
import GatesApi from '@/services/tenants/gates'

export default {
  name: 'RelatorioPerformanceRegiaoFiltroModalItemInputFornecedor',
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    },
    rules: {
      type: [Object, String],
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      search: '',
      fornecedoresSelecionados: [],
    }
  },
  methods: {
    async getFornecedorSearch(parametros) {
      try {
        const fornecedor = await GatesApi.getFornecedoresByParams(
          this.empresaAtual,
          parametros
        )

        this.fornecedoresSelecionados = fornecedor
      } catch (e) {
        console.log(e)
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'user']),
  },
  watch: {
    async search(search) {
      if (search && search.length > 2) {
        await this.getFornecedorSearch({
          pesquisa: search,
        })
      } else if (!search || search.length < 2) {
        this.fornecedoresSelecionados = []
      }
    },
  },
}
</script>
