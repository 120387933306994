<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12">
        <Filtro :permission="permission" @search="getRelatorio" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <Resumo :resumo="data.total" :permission="permission" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <div style="margin: 20px">
          <v-btn
            color="info"
            class="w-100 my-1"
            @click="getDownload"
            :disabled="!canDownload"
          >
            <v-icon class="mx-2" left dark> mdi-download </v-icon>
            Baixar Planilha</v-btn
          >
        </div>
        <v-data-table
          :headers="headers"
          :items="data.results"
          :search="search"
          sort-by="descricao"
          class="border"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Cotas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisa"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:[`item.quantidade_performance`]="{ item }">
            {{
              item.quantidade_performance.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Sequences } from '@/utils/permissions'

import Filtro from '@/components/relatorios/performance/filtro/RelatorioPerformanceRegiaoFiltro.vue'
import Resumo from './Resumo.vue'

export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  components: { Filtro, Resumo },
  data() {
    return {
      results: [],
      total: undefined,
      search: '',
    }
  },
  computed: {
    permission() {
      return Sequences.CotasRegiao.toString()
    },
  },
  methods: {
    async getRelatorio(params) {
      await this.$emit('getRelatorio', params)
    },
    async getDownload() {
      await this.$emit('getDownload')
    },
  },
}
</script>
